import React, { useState, useEffect } from 'react';
import s from './assest/s1.jpg'
import ss from './assest/sslogo.png'
import './App.css'
import Loader from './Traingleload'

function App() {
  const [files, setFiles] = useState([]);
  const [results, setResults] = useState([]);
  const [loader,setLoader]=useState(false);


  const backtoHome=()=>{
      setFiles();
      setLoader(false);
      setResults();
  }
  const handleFileUpload = (event) => {
    const fileList = event.target.files;
    console.log(fileList);
    const fileArray = Array.from(fileList);
    setFiles(fileArray);
  };

  // const processImages = () => {
  //   const pairs = groupImagePairs(files);
  //   console.log("Pairs",pairs);
  //   Promise.all(
  //     pairs.map((pair) =>
  //       Promise.all(
  //         pair.map((file) => getBase64(file))).then((images) =>
  //         callAPI(images[0], images[1])
  //       )
  //     )
  //   ).then((responses) => setResults(responses));
  // };

  function processImages() {
    console.log(files);
    const pairs = groupImagePairs(files);
    // setLoading(true);
    setLoader(true);
    console.log(pairs)
    const promises = pairs.map(pair => {
      const idproof = pair.original;
      const live = pair.copy;
      return Promise.all([getBase64(idproof), getBase64(live)])
        .then(data => {
          return callAPI(data[0], data[1]);
        })
        .catch(error => {
          console.error(error);
        });
    });
  
    Promise.all(promises)
      .then(results => {
        setResults(results);
        setLoader(false)
        console.log(files[0])
        // setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoader(false)
        console.log(files[0])

        // setLoading(false);
      });
  }

  const groupImagePairs = (files) => {
    const pairs = {};
    const pattern = /^(.+)_(original|copy)\.(jpg|jpeg|png)$/i;
    // const pattern = /^(.+)-(idproof|live)\.(jpg|jpeg|png)$/i;
    files.forEach((file) => {
      const match = file.name.match(pattern);
      if (match) {
        const key = match[1];
        const type = match[2];
        if (!pairs[key]) {
          pairs[key] = {};
        }
        pairs[key][type] = file;
      }
    });
    return Object.values(pairs).filter((pair) => pair.original && pair.copy);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const callAPI = (image1, image2) => {
    console.log(typeof image1);
    const index = image1.indexOf('/9j'); // Get the index of "/9j" in the string
    const img1 = image1.substring(index);
    const img2 = image2.substring(index);
    return fetch('https://z25985q3p1.execute-api.us-east-1.amazonaws.com/prod/facematch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "encoded_image1":img1, "encoded_image2":img2 }),
    })
      .then((response) => response.json())
      .then((data) => data);
  };

  useEffect(() => {
    if (results?.length > 0) {
      console.log(results);
    }
  }, [results]);

  return (
    <div>
       <div className="mainContainer">
        <div className="header">
            <div className="lHead">
                <div className="lhFirst">
                  <span style={{color:"#cf0f86 "}}>x</span><span style={{color:"#329AE0"}}>Stream</span>
                </div>
                <div className="emptyLDiv"></div>
                <div className="lhDown">Image Matching AI Bot</div>
            </div>
            <div className="rHead">
              <div  className="rhlogo" style={{padding:"12px"}}>
                <img style={{width:"90%", height:"90%"}} src={ss} alt="img"/>
              </div>
              {/* <div  className="emptyVer"></div>
              <div className="rhname">stream</div> */}
            </div>
        </div>
        <div className="containt">
          <div className="imgLeft">
            <img src={s} alt="img" style={{height:"auto" , width:"95%",marginTop:"auto"}}/>
             </div>
             {
             loader ? (
              // Show loader if loading state is true
              <Loader />
            ) :
             results?.length>0?(

              <div className="dataLayer">
              <div className="dataContainer">
              <div  className="resultHead" >Image matching Results</div>
            <div style={{paddingTop:"2rem" ,overflow:"auto"}}>
            <table>
  <thead>
    <tr>
      <th>S. No</th>
      <th>Original Image</th>
      <th>Copy Image</th>
      <th>Match Result</th>
    </tr>
  </thead>
  <tbody>

     {results.map((result, index) => {
              const pair = groupImagePairs(files)[index];
              const idproofName = pair.original.name;
             
              const liveName = pair.copy.name;
              return (
                <tr style={{color:"black"}} key={index}>
                  <td>{index+1}</td>
                  <td>
                  <img src={pair.original && URL.createObjectURL(pair.original)} alt={pair.original.name} style={{ height: '50px', width: '50px' }} />
       
                   <div>
                   {idproofName} 
                    </div>
                    </td>
                  <td>
                  <img src={pair.original && URL.createObjectURL(pair.copy)} alt={pair.copy.name} style={{ height: '50px', width: '50px' }} />
                    
                    <div>{liveName}</div>
                  </td>
                  <td>
                    {result?.data.matchedFaces[0].matchResult === 1 ? 'Matched' : 'Not matched'}
                  </td>
                </tr>
              );
            })}
   
       </tbody>
       </table>
       <button onClick={backtoHome} style={{margin:"5px"}} type="button">Refresh</button>
            </div>
            </div>

            </div> ):(
               <div className="dataLayer">
               <div className="dataContainer">
              <div className="dataHead" style={{color:"#cf0f86"}}>Upload Image For Matching</div>
              <div className="uploadButton">
              <input className="fileInput" type="file" multiple onChange={handleFileUpload} placeholder="Browse file"/>
              <button  className="processButton"onClick={processImages} disabled={!files?.length}>
               Process
               </button>
              </div>
  
              <div className="instruction" >
              <div  style={{color:"#329AE0"}}>Upload Instructions</div>
              <div>
                  <ol type="1">
                     <li>Image resolutions up to 1920 x 1080 are supported</li>
                     <li>The image should be only PNG,JPG, JPEG, BMP file format.</li>
                     <li> 
                     Image pairs should be named as
                      <ul> 
                          <li>
                              xxxx_original.png
                          </li>
                          <li>
                              xxxx_copy.png
                          </li>
                      </ul>
                     </li>
                     <li>Both copy and original image need to be upload together.</li>
                     <li>Maximum batch size is 20 Images (10 original, 10 copies)</li>
                  </ol>
              </div>
              </div>
  
  
            </div>
            </div>
            )}
            
             
        
        </div>
       <div>
        <a href="https://www.streamdigitalservices.com/" >
        <div className="footer"><span style={{color:"#cf0f86 "}}>www.</span><span  style={{color:"#329AE0"}}>streamdigitalservices</span><span style={{color:"#cf0f86 "}}>.com</span></div>
        </a>
        </div> 
    </div>


























{/* 
      <input type="file" multiple onChange={handleFileUpload} />
      <button onClick={processImages} disabled={!files.length}>
        Process
      </button> */}

























      {/* {results.length > 0 && (
        <table>
        <thead>
          <tr>
            <th>Image Pair</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
            {results.map((result, index) => {
              const pair = groupImagePairs(files)[index];
              const idproofName = pair.idproof.name;
              const liveName = pair.live.name;
              return (
                <tr key={index}>
                  <td>
                    <div>{idproofName}</div>
                    <div>{liveName}</div>
                  </td>
                  <td>
                    {result.data.matchedFaces[0].matchResult === 1 ? 'Matched' : 'Not matched'}
                  </td>
                </tr>
              );
            })}
          </tbody>
      </table>
      )} */}
    </div>
  );
}

export default App;
