import React from 'react';
import './Traingleload.css';

function TriangleLoader() {
  return (
   <div className="loader"></div>

 
  );
}

export default TriangleLoader;
